<template>
  <div class="showMapWrapper">
    <el-amap
      vid="map"
      class="amap-box"
      :zoom="zoom"
      :plugin="plugin"
      :events="events"
      :center="center"
    >
      <el-amap-marker
        vid="marker"
        :position="center"
        :label="label"
      ></el-amap-marker>
    </el-amap>
  </div>
</template>

<script>
export default {
  name: "ShowMap",
  components: {},
  props: {
    selectAddress: {
      type: String,
      default: ""
    }
  },
  watch: {
    data(val, newVal) {
      if (val != newVal) {
        this.init();
      }
    },
    deep: true,
    immediate: true
  },
  data() {
    let self = this;
    return {
      zoom: 18,
      lng: 0,
      lat: 0,
      loaded: false,
      address: this.selectAddress,
      //mark的时候显示tip
      label: {
        content: this.selectAddress,
        offset: [10, 12]
      },
      center: [0, 0],
      //插件集合
      plugin: [
        //工具条
        {
          pName: "ToolBar",
          positon: "LB"
        }
      ],
      events: {
        init(map) {
          // 这里通过高德 SDK 完成。
          var geoCoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all",
            city: "全国"
          });
          geoCoder.getLocation(self.address, (status, result) => {
            if (status === "complete" && result.geocodes.length) {
              let lnglat = result.geocodes[0].location;
              self.lng = lnglat.lng;
              self.lat = lnglat.lat;
              self.center = [self.lng, self.lat];
            }
          });
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.showMapWrapper {
  width: 100vw;
  height: 100vh;
}
</style>
