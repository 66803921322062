<template>
  <div class="myIndexWrap">
    <!-- 根据地址信息显示地图位置 -->
    <div class="addrMapWrapper">
      <!-- <h3>根据地址信息显示地图位置</h3> -->
      <div class="addrWrapper">
        <!-- <p>{{ selectAddress }}</p> -->
        <ShowMap class="showMapBox" :selectAddress="selectAddress" />
      </div>
    </div>
  </div>
</template>

<script>
import ShowMap from "@/components/Map/showMap.vue"; //地图标记

export default {
  name: "Map",
  components: {
    ShowMap
  },
  data() {
    return {
      selectAddress: "南昌市青山湖区俊彦路与火炬五路交叉口西南80米"
    };
  },
  beforeMount() {
    console.log(this.$route.query);
    this.selectAddress = this.$route.query.address;
  }
};
</script>

<style lang="scss" scoped>
.myIndexWrap {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-flow: column;
}
</style>
